// 产品服务tabs
export const TABS = ["地产开发", "商业运营", "物业服务"];

// 地产开发简介
export const DWELL_SUGGEST =
  "以“科技、健康、绿色”的设计理念打造“健康住宅”，提升企业产品核心竞争力，也为住户提供绿色健康、生态宜居的人居环境，让生活回归自然舒畅。以制造业“过度研发”之精神打造未来生活架构，创新颠覆传统地产行业理念，引领健康生活模式变革。";

// 住宅城市列表
export const DWELL_CITY_LIST = [
  "京津冀城市群",
  "长三角城市群",
  "华中城市群",
  "成渝城市群",
  "粤港澳大湾区",
];

// 住宅城市位置列表
export const DWELL_POS_LIST = ["全国"];

// 住宅城市销售状态列表
export const DWELL_SALE_LIST = ["全部"];

// 住宅列表
export const DWELL_LIST = [
  {
    city: "保定",
    name: "长城家园",
    address: "河北省保定市莲池区朝阳南大街2066",
    phone: "010-8888888",
    img: require('../../assets/imgs/dwell-1.png')
  },
  {
    city: "保定",
    name: "爱情城",
    address: "保定市莲池区太行路与益民街交叉路口东北侧左转100米处两行展示",
    phone: "010-8888888",
    img: require('../../assets/imgs/dwell-2.png')
  },
  {
    city: "天津",
    name: "爱情·花园里",
    address: "天津市津南区丰泽道八里台第一中学西侧",
    phone: "010-8888888",
    img: require('../../assets/imgs/dwell-3.png')
  },
  {
    city: "天津",
    name: "爱情·缤纷里",
    address: "天津市西青区宾水西道8888号",
    phone: "010-8888888",
    img: require('../../assets/imgs/dwell-4.png')
  },
  {
    city: "日照",
    name: "芳华里",
    address: "山东省日照市东港区太公岛一路与山海天路交汇处",
    phone: "010-8888888",
    img: require('../../assets/imgs/dwell-5.png')
  },
];

// 商业运营介绍内容
export const OPERATE_SUGGEST = '集商业开发及商业运营管理于一体，致力于打造突破业态边界、颠覆传统，引领全新的汽车服务模式和商业结合的商业品类品牌，通过商业模式创新，将汽车产业与商业、科技充分融合，以运营换流量，以服务强运营，满足消费者汽车体验和娱乐休闲消费的双重目的。';

// 商业运营房子内容
export const OPERATE_ROOM_LIST = [
  {
    bg: require('../../assets/imgs/operate-1.png'),
    city: '天津西青',
    name: '爱情广场',
    enName: 'AIQING GUANGCHANG',
    suggest: '',
  },
  {
    bg: require('../../assets/imgs/operate-2.png'),
    city: '河北保定',
    name: '爱情广场',
    enName: 'AIQING GUANGCHANG',
    suggest: '项目集合了“高层住宅、花园洋房、绿建住宅、商业mall、写字楼、商业金街、高端酒店”等多元业态产品，未来这里将成为区域范围内，功能最完善、交通最便捷、环境最优美、生态最宜居的全业态汽车主题示范城，重塑保定未来人居生活新标杆。22万㎡一站式全业态、综合性商业中心；全业态、全客层、全时段城市中心，引领保定新中心、城市新地标',
  },
  {
    bg: require('../../assets/imgs/operate-3.png'),
    city: '重庆永川',
    name: '爱情广场',
    enName: 'AIQING GUANGCHANG',
    suggest: '',
  },
];

// 物业服务介绍内容
export const ESTATE_SUGGEST = '爱情物业是中国物业管理协会会员单位，拥有丰富的物业管理经验及高素质人才服务队伍。<br/>目前，公司已经布局京津冀、华东、华中、成渝、长三角、珠三角等最具活力的城市群，管理面积超过800万平方米，物业涵盖住宅服务、商办服务、案场服务等多业务版块。';

// 物业服务基本信息列表
export const ESTATE_INFO_LIST = [
  {
    num: '2012',
    dw: '年',
    name: '成立于'
  },
  {
    num: '800w',
    dw: 'm²',
    name: '管理面积'
  },
  {
    num: '20',
    dw: '',
    name: '入驻城市'
  },
];

// 物业服务-服务体系数据
export const ESTATE_SYSTEM_INFO = {
  name: '服务体系',
  suggest: '针对社区建筑业态，和广大业主对物业服务的内容、质量、细节品味等多方面的需求，爱情物业以更加贴心的服务形式，推出“六新服务”体系，以满足社区业主精神和实际生活层面的服务需求，让业主真切体会感受到“以爱相伴，情暖一生”的幸福暖意。'
}

//服务体系-功能列表
export const ESTATE_SYSTEM_LIST = [
  {
    img: require('../../assets/imgs/system-1.png'),
    name: '新引擎',
    suggest: '潜心研发并应用科技互联网技术，打造万物互联的智慧科技物业。',
  },
  {
    img: require('../../assets/imgs/system-2.png'),
    name: '新互联',
    suggest: '以信息化为驱动，全场景数字化赋能，实现社区人与物的互联互通。',
  },
  {
    img: require('../../assets/imgs/system-3.png'),
    name: '新平台',
    suggest: '基于全栈场景AI和大数据的物联网平台，释放智慧社区无限潜力。',
  },
  {
    img: require('../../assets/imgs/system-4.png'),
    name: '新关怀',
    suggest: '坚持“以爱相伴，情暖一生”的服务理念，服务有温度，工作有态度。',
  },
  {
    img: require('../../assets/imgs/system-5.png'),
    name: '新帮手',
    suggest: '立体化社区智能硬件体系，构建社区安全生态圈，全维度贴心守护社区安全。',
  },
  {
    img: require('../../assets/imgs/system-6.png'),
    name: '新纽带',
    suggest: '一站式社区生活服务平台，打造和谐社区，为业主提供高品质智慧生活服务。',
  },
];

// 服务品类
export const ESTATE_TYPE_INFO = {
  name: '服务品类',
  enName: 'OUR SERVICE',
  suggest: '爱情物业服务包括基础物业服务、智慧物业平台、社区多元化经营和社区活动四大类。在保障高品质基础服务的前提下，通过创新，对物业服务的内容进行升级和扩展，不断满足业主与时俱进的生活服务需求。',
}

export const ESTATE_TYPE_LIST = [
  {
    name: '基础物业服务',
    suggest: '·秩序维护·绿化清洁·工程维护·管家服务',
  },
  {
    name: '智慧物业平台',
    suggest: '·物业ERP·智能巡检·在线报修/缴费',
  },
  {
    name: '社区多元化经经营',
    suggest: '·房屋租售/装修·社区商业/家政',
  },
  {
    name: '社区活动',
    suggest: '·常态·公益·教育·运动',
  },
];